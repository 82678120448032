/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background: url("./assets/background.svg") no-repeat top center, #F7F7F7;
    background-size:contain;
    height: 100vh;
    color: #707070;
    box-sizing: border-box;
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
}
#root{

    background: inherit;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}